import customSoftwareSolutions from '../assets/images/custom-solutions.jpg';
import webDevelopment from '../assets/images/Web-Development.jpg';
import mobileApps from '../assets/images/mobile_apps.jpeg';
import cloudSolutions from '../assets/images/cloud_solutions.jpg';
import dataAnalytics from '../assets/images/data_analytics.jpg';
import cybersecurity from '../assets/images/cybersecurity.jpg';
import ai from '../assets/images/ai.jpg';
import integrationservices from '../assets/images/integration.jpg';
import devops from '../assets/images/devops.jpg';
import blockchain from '../assets/images/blockchain.jpg';
import support from '../assets/images/support.png';
import collaboration from '../assets/images/collaboration.jpeg';
import eLearning from '../assets/images/elearning.jpg';
import iot from '../assets/images/iot.jpg';
import testing from '../assets/images/testing.webp';

export const ITServicesConstants = [
    {
        title: "Custom Software Solutions",
        image: customSoftwareSolutions,
        description: "Tailored applications developed to meet specific business needs, including CRM systems, ERP solutions, and more."
    },
    {
        title: "Web Development",
        image: webDevelopment,
        description: `Websites and web applications built using various frameworks and technologies, including:
        \nContent Management Systems (CMS)
        \nE-commerce platforms
        \nSingle Page Applications (SPAs)`
    },
    {
        title: "Mobile Applications",
        image: mobileApps,
        description: "Native and hybrid apps for iOS and Android, catering to different business requirements and user experiences."
    },
    {
        title: "Cloud Solutions",
        image: cloudSolutions,
        description: "Services such as cloud storage, cloud hosting, and cloud-based applications that enhance flexibility and scalability"
    },
    {
        title: "Data Analytics and Business Intelligence",
        image: dataAnalytics,
        description: "Tools and software for data collection, analysis, and visualization to help businesses make informed decisions"
    },
    {
        title: "Cybersecurity Solutions",
        image: cybersecurity,
        description: "Software to protect data and systems, including firewalls, antivirus software, and intrusion detection systems"
    },
    {
        title: "Artificial Intelligence (AI) and Machine Learning (ML) Solutions",
        image: ai,
        description: "Applications that leverage AI and ML for automation, predictive analytics, and enhanced user experiences"
    },
    {
        title: "Integration Services",
        image: integrationservices,
        description: "Solutions that connect various software systems, ensuring seamless data flow and improved operational efficiency"
    },
    {
        title: "DevOps Tools",
        image: devops,
        description: "Software for continuous integration and deployment, facilitating collaboration between development and operations teams"
    },
    {
        title: "Support and Maintenance Services",
        image: support,
        description: "Ongoing technical support, software updates, and maintenance to ensure optimal performance and security"
    },
    {
        title: "Collaboration Tools",
        image: collaboration,
        description: "Software that enhances team communication and collaboration, such as project management tools, chat applications, and video conferencing platforms"
    },
    {
        title: "E-learning Solutions",
        image: eLearning,
        description: "Learning management systems (LMS) and educational software for online training and education"
    },
    {
        title: "IoT Solutions",
        image: iot,
        description: "Software designed for managing Internet of Things devices, enabling connectivity and data exchange"
    },
    {
        title: "Testing and Quality Assurance Tools",
        image: testing,
        description: "Software for automating testing processes to ensure the quality and reliability of applications"
    },
    {
        title: "Blockchain Solutions",
        image: blockchain,
        description: "Applications and platforms leveraging blockchain technology for secure transactions and data integrity"
    }
];
