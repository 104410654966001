import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NavBar from './pages/NavBar';
import Home from './pages/Home';
import ITServices from './pages/ITServices';
import Products from './pages/Products';
import ContactUs from './pages/ContactUs';
import Footer from './pages/Footer';

function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <NavBar />
      <main className="flex-grow">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/itservices" element={<ITServices />} />
          <Route path="/products" element={<Products />} />
          <Route path="/contact" element={<ContactUs />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;