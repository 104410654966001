import p1 from '../assets/images/pumpspares/Impellers.png'
import p2 from '../assets/images/pumpspares/Diffuser.png'
import p3 from '../assets/images/pumpspares/Shaft.png'
import p4 from '../assets/images/pumpspares/Bearings.png'
import p5 from '../assets/images/pumpspares/Bushes.png'
import p6 from '../assets/images/pumpspares/vanes.png'
import p7 from '../assets/images/pumpspares/OilSeals.png'
import p8 from '../assets/images/pumpspares/Gaskets.png'
import p9 from '../assets/images/pumpspares/Volute.png'
import p10 from '../assets/images/pumpspares/tapperlock.png'
import p11 from '../assets/images/pumpspares/Tyrecoupling.png'
import p12 from '../assets/images/pumpspares/BallValveSeries.png'
import p13 from '../assets/images/pumpspares/GateValve.png'
import p14 from '../assets/images/pumpspares/ButterflyValve.png'
import p15 from '../assets/images/pumpspares/CheckValve.png'

export const PumpSpares = [
    {
        id: 1,
        name: 'Impellers',
        image: p1,
    },
    {
        id: 2,
        name: 'Diffuers',
        image: p2,
    },
    {
        id: 3,
        name: 'Shaft',
        image: p3,
    },
    {
        id: 4,
        name: 'Bearings',
        image: p4,
    },
    {
        id: 5,
        name: 'Bushes',
        image: p5,
    },
    {
        id: 6,
        name: 'Vanes For Vaccum Pumps',
        image: p6,
    },
    {
        id: 7,
        name: 'Oil Seals',
        image: p7,
    },
    {
        id: 8,
        name: 'Gaskets',
        image: p8,
    },
    {
        id: 9,
        name: 'Volute',
        image: p9,
    },
    {
        id: 10,
        name: 'Tapper Lock & Pulley',
        image: p10,
    },
    {
        id: 11,
        name: 'Tyre Coupling F60-F200',
        image: p11,
    },
    {
        id: 12,
        name: 'Ball Valve Series',
        image: p12,
    },
    {
        id: 13,
        name: 'Gate Valve',
        image: p13,
    },
    {
        id: 14,
        name: 'Butterfly Valve',
        image: p14,
    },
    {
        id: 15,
        name: 'Check Valve',
        image: p15,
    },
]
