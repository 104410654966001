import React from 'react';

function AboutUs() {
    return (
        <div className="mx-auto mt-10 max-w-4xl font-titillium">
            <div className="space-y-4 text-lg">
                <p>
                    <strong className="text-xl text-blue-600">DJM TradeCorp</strong> is your trusted partner for IT software and water pump solutions, dedicated to driving success in a rapidly evolving market. Our goal is to empower businesses across various sectors with innovative tools and reliable systems.
                </p>
                <p>
                    Our IT division focuses on developing cutting-edge software tailored to meet the unique needs of our clients. We offer custom software development to create bespoke applications that enhance productivity and efficiency, along with cloud solutions that provide scalability and accessibility while ensuring robust security.
                </p>
                <p>
                    Additionally, our data analytics tools empower businesses to harness their data for real-time analysis, enabling informed decision-making and strategic planning.
                </p>
                <p>
                    Our water pump division delivers high-quality pumping solutions for agricultural, industrial, and municipal applications. Our product range includes:
                </p>
                <ul className="list-disc list-inside ml-4">
                    <li>Submersible pumps</li>
                    <li>Centrifugal pumps</li>
                    <li>Diaphragm pumps</li>
                </ul>
                <p>
                    These are engineered for reliability and efficiency in demanding environments. Our products are built with the idea that innovation and sustainability should go hand in hand. We prioritize energy efficiency by designing our pumps to minimize energy consumption while maximizing output, which helps clients reduce operational costs. Our comprehensive support services include expert installation and maintenance to ensure optimal performance and longevity of the systems.
                </p>
                <p>
                    Our commitment to continuous improvement keeps us at the forefront of industry advancements, ensuring we provide the best products and services available.
                </p>
            </div>
        </div>
    )
}

export default AboutUs;
