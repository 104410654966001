import React from 'react';
import { PhoneIcon, EnvelopeIcon } from '@heroicons/react/24/solid';
import Footer from './Footer';

function ContactUs() {
    const emailAddress = 'admin@djmtradecorp.org';
    const handleSendEmail = () => {
       
        const subject = 'Customer Inquiry';
        const body = 'Hello, I have a question about...';
        const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;
    };

    return (
        <>
            <div className="bg-gradient-to-br from-blue-100 via-blue-50 to-white py-16">
                <div className="contact-us-container bg-white rounded-lg shadow-xl p-8 max-w-2xl mx-auto">
                    <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">How can we help?</h1>
                    
                    <section className="contact-options grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="contact-option bg-blue-50 rounded-lg p-6 text-center flex flex-col items-center justify-center">
                            <PhoneIcon className="h-8 w-8 text-blue-500 mb-2" />
                            <h2 className="text-xl font-semibold text-gray-700 mb-2">Call Us</h2>
                            <p className="text-lg text-blue-600">+44 7831 782748</p>
                        </div>
                        <div className="contact-option bg-blue-50 rounded-lg p-6 flex flex-col items-center justify-center">
                            <EnvelopeIcon className="h-8 w-8 text-blue-500 mb-2" />
                            <button 
                                onClick={handleSendEmail}
                                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105"
                            >
                                {emailAddress}
                            </button>
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ContactUs;