import React from 'react';
import { Link } from 'react-router-dom';
import { PhoneIcon, EnvelopeIcon } from '@heroicons/react/24/solid';

function Footer() {
    const emailAddress = 'admin@djmtradecorp.org';
    const handleSendEmail = () => {
        const subject = 'Customer Inquiry';
        const body = 'Hello, I have a question about...';
        const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;
    };

    return (
        <footer className="bg-gray-800 text-white font-titillium">
            <div className="container mx-auto px-4 py-8">
                <div className="flex flex-wrap justify-between">
                    <div className="w-full md:w-1/4 mb-6 md:mb-0">
                        <h2 className="text-2xl font-bold mb-4">DJM TRADECORP</h2>
                        <p className='capitalize'>© 2024 DJM TRADECORP. ALL RIGHTS RESERVED.</p>
                    </div>
                    <div className="w-full md:w-1/4 mb-6 md:mb-0">
                        <h3 className="text-xl font-bold mb-4">QUICK LINKS</h3>
                        <ul>
                            <li className="mb-2"><Link to="/" className="hover:text-gray-300">HOME</Link></li>
                            <li className="mb-2"><Link to="/itservices" className="hover:text-gray-300">IT SERVICES</Link></li>
                            <li className="mb-2"><Link to="/products" className="hover:text-gray-300">WATER PUMP SOLUTIONS</Link></li>
                        </ul>
                    </div>
                    <div className="w-full md:w-1/4">
                        <h3 className="text-xl font-bold mb-4">GET IN TOUCH</h3>
                        <div className="flex flex-col space-y-4">
                            <div className="flex items-center">
                                <PhoneIcon className="h-6 w-6 text-white mr-2" />
                                <p>+44 7831 782748</p>
                            </div>
                            <div className="flex items-center">
                                <EnvelopeIcon className="h-6 w-6 text-white mr-2" />
                                <button 
                                    onClick={handleSendEmail}
                                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
                                >
                                   {emailAddress.toUpperCase()}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
