import { useLocation, Link } from "react-router-dom";
import { Bars3Icon } from "@heroicons/react/24/solid";
import dmlogo from "../assets/images/dmlogo.png";
import { useState } from "react";

function NavBar() {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navItems = [
    { path: '/', label: 'Home' },
    { path: '/itservices', label: 'IT Services' },
    { path: '/products', label: 'Water Pump Solutions' }
  ];

  return (
    <nav className="bg-white shadow-md font-titillium text-xl capitalize">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-20">
          <Link to="/" className="flex-shrink-0">
            <img
              alt="DJM TradeCorp"
              src={dmlogo}
              className="h-10 w-auto cursor-pointer"
              style={{ imageRendering: "pixelated"}}
            />
          </Link>
          <div className="hidden md:flex items-center space-x-6">
            {navItems.map((item) => (
              <Link 
                key={item.path}
                className={`rounded-md px-4 py-2 font-titillium cursor-pointer text-center whitespace-nowrap ${
                  location.pathname === item.path 
                    ? 'bg-djmTextColor text-white' 
                    : 'text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                }`}
                to={item.path}
              >
                {item.label}
              </Link>
            ))}
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-700 hover:text-gray-900 focus:outline-none"
            >
              <Bars3Icon className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {navItems.map((item) => (
              <Link 
                key={item.path}
                className={`block rounded-md px-3 py-2 font-titillium ${
                  location.pathname === item.path 
                    ? 'bg-djmTextColor text-white' 
                    : 'text-gray-700 hover:bg-gray-100 hover:text-gray-900'
                }`}
                to={item.path}
              >
                {item.label}
              </Link>
            ))}
          </div>
        </div>
      )}
    </nav>
  )
}

export default NavBar;
