import p1 from '../assets/images/waterpumpsolutions/HorizontalSingleCentrigual.png'
import p2 from '../assets/images/waterpumpsolutions/HorizontalMultiStagePump.png'
import p3 from '../assets/images/waterpumpsolutions/Vertical.png'
import p4 from '../assets/images/waterpumpsolutions/SewagePumps.png'
import p5 from '../assets/images/waterpumpsolutions/SubmersibleDrainagePumps.png'
import p6 from '../assets/images/waterpumpsolutions/DewateringPumps.png'
import p7 from '../assets/images/waterpumpsolutions/SurfacePumps.png'
import p8 from '../assets/images/waterpumpsolutions/highpressure.png'

export const ProductCategories = [
    {
        id: 1,
        name: 'Horizontal Single Stage Centrifugal Pumps',
        image: p1,
    },
    {
        id: 2,
        name: 'Horizontal MultiStage Centrifugal Pumps',
        image: p2,
    },
    {
        id: 3,
        name: 'Vertical MultiStage Centrifugal Pumps',
        image: p3,
    },
    {
        id: 4,
        name: 'Sewage Pumps',
        image: p4,
    },
    {
        id: 5,
        name: 'Submersible Drainage Pumps',
        image: p5,
    },
    {
        id: 6,
        name: 'Dewatering Submersible  Pumps',
        image: p6,
    },
    {
        id: 7,
        name: 'Engine Driven / Surface Pumps',
        image: p7,
    },
    {
        id: 8,
        name: 'High Pressure Pumps',
        image: p8,
    }
]